import { ref } from 'vue';

export const useIsMobile = () => {
    const isMobile = ref(false);

    if (document.body.clientWidth < 1000) {
        isMobile.value = true;
    }

    window.addEventListener('resize', () => {
        if (document.body.clientWidth <= 991) {
            isMobile.value = true;
        } else {
            isMobile.value = false;
        }
    });

    return isMobile;
}

export const useIsSmallScreen = () => {
    const isSmallScreen = ref(false);

    if (document.body.clientWidth < 1400) {
        isSmallScreen.value = true;
    }

    window.addEventListener('resize', () => {
        if (document.body.clientWidth <= 1400) {
            isSmallScreen.value = true;
        } else {
            isSmallScreen.value = false;
        }
    });

    return isSmallScreen;
}